<template>
  <div class="menu_management">
    <div class="container">
      <el-card>
        <el-button type="primary" @click="addMenu" :disabled="addMenuBtnDisabled">新增</el-button>
        <!-- <el-button class="colortheme" @click="addMenu">+添加</el-button> -->
        <el-table
          style="margin-top: 16px"
          :data="tableData"
          row-key="id"
          lazy
          :header-cell-style="cellStyle"
        >
          <el-table-column prop="id" label="ID" width="100" align="center" />
          <TableTreeColumn
            prop="authName"
            treeKey="id"
            label="名称"
            align="center"
            min-width="200px"
          />
          <el-table-column prop="icon" label="图标" align="center" min-width="80px">
            <template v-slot="{ row }">
              <i :class="row.icon || ''" class="iconfont"></i>
            </template>
          </el-table-column>
          <el-table-column prop="type" label="类型" align="center" min-width="100px">
            <template v-slot="{ row }">
              <el-tag v-if="row.type === '1'" size="small">目录</el-tag>
              <el-tag v-else size="small" type="success">菜单</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="url" label="菜单URL" align="center" min-width="200px"/>
          <el-table-column class="operation" width="140" label="操作" align="center">
            <template v-slot="{ row }">
              <span class="edit" @click="editItem(row.id)">编辑</span>
              <span class="frozen" @click="deleteItem(row.id)">删除</span>
            </template>
          </el-table-column>
        </el-table>
        <el-dialog
          ref="addVisible"
          :title="dialogType ? '编辑菜单' : '新增菜单'"
          :visible.sync="dialogVisible"
          width="30%"
          :before-close="handleClose"
          @close="handleClose"
        >
          <el-form
            ref="menuManagementObj"
            :model="menuManagementObj"
            :rules="menuManagementRules"
            label-width="120px"
          >
            <el-form-item label="菜单类型" prop="menuType">
              <el-radio-group v-model="menuManagementObj.type">
                <el-radio v-for="item in menuTypeList" :key="item.value" :label="item.value">{{
                  item.label
                }}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item :label="`${menuTypeList[menuName].label}名称`" prop="name">
              <el-input
                v-model="menuManagementObj.authName"
                :placeholder="`请输入${menuTypeList[menuName].label}名称`"
              />
            </el-form-item>
            <el-form-item label="上级菜单：">
              <el-select v-model="menuManagementObj.parentId" placeholder="请选择">
                <el-option
                  v-for="item in menuList"
                  :key="item.id"
                  :label="item.authName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="菜单URL" prop="url">
              <el-input v-model="menuManagementObj.url" placeholder="请输入菜单URL" />
            </el-form-item>
            <el-form-item label="菜单图标" prop="icon">
              <el-input v-model="menuManagementObj.icon" placeholder="请输入菜单icon" />
            </el-form-item>
            <div style="margin-left: 30px">菜单修改之后，重新登录才失效</div>
          </el-form>
          <div class="text-right">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="submitForm">确 定</el-button>
          </div>
        </el-dialog>
      </el-card>
    </div>
  </div>
</template>

<script>
const defaultMenuManagementObj = Object.freeze({
  id: '', //ID
  authName: '', //名称
  icon: '', // 图标
  type: '1', // 类型
  parentName: '', // 上级菜单
  url: '', // 菜单URL
  perms: '', // 授权标识
  orderNum: '', // 排序
  parentId: ''
})
import { addRights, rightsList, deleteRights, rightsDetail, updateRights } from '@/api/system.js'
import TableTreeColumn from './TableTreeColumn.vue'
export default {
  components: { TableTreeColumn },
  name: 'menuManagement',
  data() {
    return {
      addMenuBtnDisabled: false, // 禁用新增按钮
      saveMenuBtnDisabled: false, // 禁用保存按钮
      cellStyle: {
        background: '#F7FAF8',
        color: '#1A1F33'
      },
      tableData: [], //表格数据
      dialogType: 0, // 面板类型  0 新增 1 编辑
      dialogVisible: false, // 新增编辑的弹窗
      menuManagementObj: { ...defaultMenuManagementObj },
      menuManagementRules: {
        type: [{ required: true, message: '请选择菜单类型', trigger: 'change' }],
        authName: [{ required: true, message: '请输入菜单名称', trigger: 'blur' }]
      },
      popupTreeProps: {
        label: 'name',
        children: 'children'
      },
      menuList: [], // 菜单
      menuTypeList: [
        { label: '目录', value: '1' },
        { label: '菜单', value: '2' }
      ]
    }
  },
  computed: {
    menuName() {
      return this.menuTypeList.findIndex((item) => {
        return item.value === this.menuManagementObj.type
      })
    }
  },
  created() {
    this.search()
  },
  methods: {
    //编辑单个菜单或者目录
    async editItem(id) {
      const res = await rightsDetail({ id })
      console.log(res)
      if (res.resultCode === 200) {
        Object.assign(this.menuManagementObj, res.data)
      }
      this.dialogVisible = true
      this.dialogType = 1
    },
    // 新增
    addMenu() {
      this.dialogVisible = true
      this.dialogType = 0
      this.addMenuBtnDisabled = true
      setTimeout(() => {
        this.addMenuBtnDisabled = false
      }, 1000)
    },
    //关闭弹窗
    handleClose() {
      this.$refs.menuManagementObj.resetFields()
      Object.assign(this.menuManagementObj, defaultMenuManagementObj)
      this.dialogVisible = false
    },
    //新增或者编辑
    submitForm() {
      this.$refs.menuManagementObj.validate(async (valid) => {
        if (valid) {
          this.saveMenuBtnDisabled = true
          setTimeout(() => {
            this.saveMenuBtnDisabled = false
          }, 2000)
          if (!this.menuManagementObj.parentId) {
            this.menuManagementObj.parentId = 0
          }
          const fc = this.dialogType === 0 ? addRights : updateRights
          const txt = this.dialogType === 0 ? '新增' : '编辑'
          const res = await fc(this.menuManagementObj)
          console.log(res)
          if (res.resultCode === 200) {
            this.$message.success('菜单' + txt + '成功')
            this.dialogVisible = false
            this.search()
          } else {
            this.$message.warning(res.message)
            this.dialogVisible = false
          }
        }
      })
    },
    //删除单个菜单或者目录
    deleteItem(id) {
      this.$confirm('确认删除吗 ?', '提示')
        .then(async () => {
          const res = await deleteRights({ id })
          if (res.resultCode === 200) {
            this.$message.success('删除成功')
            this.search()
          }
        })
        .catch(() => {})
    },
    //查询
    async search() {
      const query = {
        pageNum: 1,
        pageSize: 99
      }
      const res = await rightsList(query)
      if (res.resultCode === 200) {
        this.tableData = res.data.list
        this.menuList = this.tableData.filter((item) => {
          return item.type === '1'
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  height: calc(100vh - 100px);
  overflow: auto;
}
.menu_management {
  width: 100%;

  .el-table {
    /deep/tbody tr:hover > td {
      // background-color: #90c0f1;
      background-color: rgba(23, 117, 122, 0.1);
    }
  }

  /deep/ .el-table::before {
    height: 0;
  }

  /deep/ .el-table__cell {
    border: none;
  }

  /deep/ .colortheme {
    background-color: #17757a;
    color: #fff;
    border: 1px solid #17757a;
  }
  .edit {
    padding: 0 5px;
    color: #4e93fb;
    cursor: pointer;
  }
  .frozen {
    padding: 0 5px;
    color: #fd5469;
    cursor: pointer;
  }
}
</style>
